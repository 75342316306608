<template>
  <Card title="sign_in">
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="credentials.email"
          hide-details
          outlined
          dense
          background-color="white"
          color="var(--color-accent)"
          :placeholder="$t('message.email')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <router-link class="link white--text text-capitalize" to="/signup">{{
          $t("message.sign_up")
        }}</router-link>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="credentials.password"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          hide-details
          outlined
          dense
          :type="showPassword ? 'text' : 'password'"
          background-color="white"
          color="var(--color-accent)"
          :placeholder="$t('message.password')"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <router-link
          class="link white--text text-capitalize"
          to="/recovery-password"
          >{{ $t("message.recovery_password") }}</router-link
        >
      </v-col>
    </v-row>
    <v-row class="mt-4 mb-2" justify="center">
      <v-btn rounded @click="login">{{ $t("button.login") }} </v-btn>
    </v-row>

    <v-row class="caption black--text">
      <v-col align-self="center"><v-divider class="white" /></v-col>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 2 : 1"
        class="text-center white--text text-uppercase"
        align-self="center"
        >{{ $t("message.or") }}</v-col
      >
      <v-col align-self="center"><v-divider class="white" /></v-col>
    </v-row>

    <v-row class="my-3" justify="center">
      <v-btn rounded @click="loginGoogle"
        ><v-img class="mr-2" src="@/assets/icons/google-logo.svg"></v-img
        >{{ $t("button.login_google") }}
      </v-btn>
    </v-row>
  </Card>
</template>

<script>
import { mapActions } from "vuex";
import Card from "@/components/Card.vue";

export default {
  name: "Login",
  components: {
    Card,
  },
  data() {
    return {
      credentials: { email: null, password: null },
      showPassword: false,
    };
  },
  methods: {
    login() {
      this.loginWithEmailAndPassword(this.credentials);
    },
    ...mapActions(["loginGoogle", "loginWithEmailAndPassword"]),
  },
};
</script>